import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Civilitee } from '../class/unit.enum';
import { FormChangeService } from '../services/form-change.service';
import { AuthenticationService } from '../services/authentication.service';
import { AlertService } from '../services/alert-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserUpdateComponent } from './user-update/user-update.component';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  searchText;
  lstUsers = [];
  lstCusomers = [];
  displayedColumns = ['expandIcon','Nom/Prenom','Identifiant','Client','Email','lastConnection','Actions'
  ];
  civiliteeEnum = Civilitee ;
  expandedElement: {} | null;

  getKeys(object): string[] {
    return Object.keys(object);
  }

  onItemSelected(idx: number) {
    console.log(idx);
  }

  constructor(private formService: FormChangeService,
              private authenSer: AuthenticationService,
              private alertService: AlertService,
              private userService: UsersService,
              private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.subscribeformChange();
  }
  subscribeformChange() {
    this.formService.formMinUserSearchEvent.subscribe(
      (param) => {

        this.lstUsers = param;

      });

  }

  create(): void {
    const modalRef = this.modalService.open(UserCreateComponent, { backdrop: 'static' });
  }
  editUser(user) {
    const modalRef = this.modalService.open(UserUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.user = user;

  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un utilisateur";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.deleteUser(id);
      }
    });
  }

  deleteUser(userId) {
    this.userService.deleteUser(userId).subscribe(
      () => {
        this.onDeleteSuccess();
      },
      () => this.onDeleteError()
    );

  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raisereloadSearchRequestUserEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }

}

