import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { soncas, prospectStatus, Share } from '../class/unit.enum';
import { Router, ActivatedRoute } from '@angular/router';
import { ProspectService } from '../services/prospect.service';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { ProductService } from '../services/product.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from '../services/form-change.service';
import * as moment from 'moment';
import { TenantService } from '../services/tenant.service';

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit, OnDestroy {
  parentRouteId: number;
  private sub: any;

  form;
  tenants;
  keys = Object.keys;
  shareEnum = Share;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected employeeService: ProspectService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private authenSer: AuthenticationService,
    private tenantSer: TenantService,
    public activeModal: NgbActiveModal,
    private formService: FormChangeService,
    ) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    if(this.authenSer.isSupperAdmin){
      this.tenantSer.getTenants().subscribe(
        (data) => {
          this.tenants = data;

        },
        (error) => {
          this.alertService.error(error);
        }
      )
    } else {
      let user = this.authenSer.getCurrentUser();
    }
    this.initForm();

  }

  initForm() {
    this.form = this.fb.group({
      name: new FormControl(""),
      gratification: new FormControl(""),
      desc: new FormControl(""),
      isPublic: [true],
      menu: '',
      share: new FormControl(this.shareEnum.NONE),
      tenantId: '',
    });
  }

  private createFromForm(): any {
    let tmp = {
      name: this.form.get(["name"]).value,
      gratification: this.form.get(["gratification"]).value,
      desc:this.form.get(["desc"]).value,
      isPublic: this.form.get(["isPublic"]).value,
      menu: this.form.get(["menu"]).value ,
      share: this.form.get(["share"]).value,
      tenantId: this.form.get(["tenantId"]).value,
    };
    return tmp;
  }
  save(): void {
    this.activeModal.close(this.createFromForm());

  }

}
