import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ICustomer, Customer } from 'src/app/class/customer';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { User, IUser } from 'src/app/class/user';
import { Role } from 'src/app/class/role';
import { UsersService } from 'src/app/services/users.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.scss']
})
export class UserUpdateComponent implements OnInit {

  userUpdateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  // tenantId;
  selectedCivilite: string = null;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  userForm: any;
  selectedCustomer;
  selectedTenant;
  selectedProducts = new Array();
  selectedRoles = new Array();
  roles = new Array();
  tenants: Object;
  user: any;
  products;
  constructor(
    protected userService: UsersService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private productService: ProductService,
    private formService: FormChangeService,
    private authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    ) {
      this.getRoles();
      this.getProducts();
    }

  ngOnInit(): void {
    this.initForm();
    this.updateForm(this.user);

  }

  initForm() {
    this.userUpdateForm = this.fb.group({
      id: [],
      firstname: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      login: ['',Validators.required],
      tenantId: [],
    });

  }
  getRoles() {
    if(this.authenSer.isSupperAdmin){
      this.userService.getRoles().subscribe(
        (data) =>{
          this.roles = data as Array<any>;
        },
        (error)=>{
          console.log(error);
        });
    }
  }

  get f() { return this.userUpdateForm.controls; }

  private createFromForm(): any {
    let tmp=  {
      id: this.userUpdateForm.get(['id']).value,
      login: this.userUpdateForm.get(['login'])!.value,
      email: this.userUpdateForm.get(['email'])!.value,
      firstname: this.userUpdateForm.get(['firstname'])!.value,
      name: this.userUpdateForm.get(['name'])!.value,
      roles:this.selectedRoles,
      productIds: this.selectedProducts,
    };
    if(this.authenSer.isSupperAdmin){
      tmp['tenantId'] = this.userUpdateForm.get(['tenantId'])!.value;
    } else {
      tmp['tenantId'] = this.user.tenantId;
    }
    return tmp;
  }
  // dans le cas de l'update on récupère les données pour les afficher
  updateForm(user: any): void {

    this.userUpdateForm.patchValue({
      id: user.id,
      firstname: user.firstname,
      name: user.name,
      email: user.email,
      login: user.login,
      roles: user.roles,
    });
    user.roles.forEach(userRol =>{
      this.selectedRoles.push(userRol);
    });

    let userproducts = this.authenSer.userApps as Array<any>;
    user.productIds.forEach(element => {

      this.selectedProducts.push(element);
    });


  }


  onSubmit(): void {
    this.submitted = true;

    // stop here if form is invalid
    if (this.userUpdateForm.invalid) {
      return;
    } else {

      this.userForm = this.createFromForm();
      debugger;
      this.subscribeToSaveResponse(this.userService.putUser(this.userForm));

    }

  }

  private findTextInCustomers(customers, text) {
    text = text?text.split(' '):'';
    return customers.filter(function (item) {

      return text.every(function (el) {
        return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
          || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }

  getProducts() {
    this.products = new Array();
    this.productService.getProducts().subscribe(
      (data) => {

        this.products = data;
      },
      (error) => {
        this.alertService.error(error);
      }
    )
  }
  resetPwd() {

    let result = this.authenSer.resetPassword(this.user.login);
    result.subscribe(
      (result) => this.onResetSuccess(result),
      (result) => this.onResetError(result)
    )
  }
  protected onResetSuccess(result): void {
    this.alertService.success('Email de changement de mot de passe envoyé. Veuillez consulter votre boîte au lettre.', true);
    this.close();
    this.formService.raisereloadSearchRequestUserEvent();
  }

  protected onResetError(result): void {
    this.alertService.error('Erreur dans le formulaire !', true);
  }
  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<ICustomer>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Utilisateur sauvegardé !', false);
    this.close();
    this.formService.raisereloadSearchRequestUserEvent();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', false);
  }
  private getFilter() {

    let filter = this.searchText ? this.searchText : null;
    return filter;
  }
}


