import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import * as moment from "moment";
import { AddCardModalComponent } from "./add-card-modal/add-card-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddSepaModalComponent } from './add-sepa-modal/add-sepa-modal.component';
import { CreditCardService } from '../services/credit-card.service';
import { TenantService } from '../services/tenant.service';
// Before the component
declare const Stripe: any;
@Component({
  selector: "app-credit-card-form",
  templateUrl: "./credit-card-form.component.html",
  styleUrls: ["./credit-card-form.component.scss"],
})
export class CreditCardFormComponent implements OnInit {
  stripe;
  card;
  loading = false;
  cards = new  Array<any>();
  ibans = new  Array<any>();
  customer: any;
  constructor(
    private fb: FormBuilder,
    private creditCardServ: CreditCardService,
    private modalService: NgbModal,
    private tenantService: TenantService
  ) {
  }

  addCard(): void {
    const modalRef = this.modalService.open(AddCardModalComponent, {
      backdrop: "static",
    });
    modalRef.componentInstance.customer = this.customer;
    modalRef.result.then((result) => {
      if(result){
        //Card ajoutée
        this.loadData();
      }
    });
  }
  addIban() {
    const modalRef = this.modalService.open(AddSepaModalComponent, {
      backdrop: "static",
    });
    modalRef.componentInstance.customer = this.customer;
    modalRef.result.then((result) => {
      if(result){
        //IBAN ajouté
        this.loadData();
      }
    });
  }
  loadStripe() {
    if (!window.document.getElementById("stripe-custom-form-script")) {
      const s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.async = false;
      s.charset = "utf-8";
      s.src = "https://js.stripe.com/v3/";
      s.onload = this.initStripeElements();
      document.getElementsByTagName("head")[0].appendChild(s);
    }
  }
  initStripeElements(): (this: GlobalEventHandlers, ev: Event) => any {
    this.creditCardServ.getPublicKey().subscribe(
      (data) => {
        let publicKey = data["publicKey"];
        this.stripeElements(publicKey);
      },
      (error) => {}
    );
    return;
  }
  stripeElements(publicKey) {

    this.stripe = Stripe(publicKey);
    var elements = this.stripe.elements();

    // Element styles
    let style = {
      base: {
        fontSize: "16px",
        color: "#32325d",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "rgba(0,0,0,0.4)",
        },
      },
    };

    this.card = elements.create("card", { style: style });
    this.card.mount("#card-element");
    this.card.on("focus", function () {
      var el = document.getElementById("card-element");
      el.classList.add("focused");
    });

    this.card.on("blur", function () {
      var el = document.getElementById("card-element");
      el.classList.remove("focused");
    });
  }
  loadData(){
    this.creditCardServ.getCusstomerPaymentMethods().subscribe(
      (res) => {
        this.cards = new Array();
        this.ibans = new Array();
        let data = res as Array<any>;
        data.forEach(element => {
            let tmp = element['card'];
            if(tmp){
            if (tmp["expMonth"] && tmp["expMonth"].length < 2) {
              tmp["expMonth"] = "0" + tmp["expMonth"];
            }

            if (tmp["expYear"] && tmp["expYear"].length > 2) {
              tmp["expYear"] = tmp["expYear"].substr(tmp["expYear"].length - 2);
            }

            this.cards.push(tmp);
          }
          if(element['iban']){
            this.ibans.push(element['iban']);
          }
        });

      },
      (error) => {}
    );
    this.tenantService.getTenants().subscribe(
      (data)=>{
        this.customer = data;
      },
      (error)=>{
        console.log(error);
      }
    )
  }
  ngOnInit() {
    this.loadData();
  }

  changeLoadingState(isLoading) {
    document.querySelector("#spinner").classList.toggle("hidden");
    this.loading = isLoading;
  }
  showCardError(error) {
    this.changeLoadingState(false);
    // The card was declined (i.e. insufficient funds, card has expired, etc)
    var errorMsg = document.querySelector(".sr-field-error");
    errorMsg.textContent = error.message;
    setTimeout(function () {
      errorMsg.textContent = "";
    }, 8000);
  }

  orderComplete(subscription) {

    this.changeLoadingState(false);
    // this.expandForm = false;
    this.cards.push(subscription);
  }
getcards(){
  return this.cards;
}
getIbans(){
  return this.ibans;
}
  registerCard(paymentMethod) {
    this.creditCardServ.registerCard(paymentMethod).subscribe(
      (data) => {
        this.orderComplete(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  ProcessCreateCardResault(result) {

    if (result.error) {
      this.showCardError(result.error);
    } else {
      this.registerCard(result.paymentMethod.id);
    }
  }
  pay() {

    this.changeLoadingState(true);
    const elements = this.stripe.elements();
    this.stripe
      .createPaymentMethod({
        type: "card",
        card: this.card,
        billing_details: {
          name: "Jenny Rosen",
        },
      })
      .then((result) => this.ProcessCreateCardResault(result));
  }
}
